.LoadingView_container__3mejg {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #fff;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .LoadingView_container__3mejg > .LoadingView_spinner__2givP {
    color: #15df89;
    font-size: 80px; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

.tb-l-text {
  line-height: 1.42857em; }
  .tb-l-text.tb-l-text-size--default {
    font-size: 16px; }
  .tb-l-text.tb-l-text-size--sm {
    font-size: 12px; }
  .tb-l-text.tb-l-text-size--lg {
    font-size: 20px; }
  .tb-l-textstrong, .tb-l-text.tb-l-text--semibold {
    font-weight: 600; }
  .tb-l-text.tb-l-text--bold {
    font-weight: 700; }
  .tb-l-text.tb-l-text-align--right {
    text-align: right; }

html,
body {
  min-height: 100%; }

.Banner_full-height__GkTTF {
  min-height: 100%; }

.Banner_tb-margin-bottom-large__3jKjd {
  margin-bottom: 24px;
  display: block; }

.Banner_tb-margin-bottom-larger__17Gaa {
  margin-bottom: 40px;
  display: block; }

.Banner_tb-margin-bottom-medium__1fNvl {
  margin-bottom: 16px;
  display: block; }

.Banner_tb-margin-bottom-small__1ktfu {
  margin-bottom: 8px !important;
  display: block; }

.Banner_tb-margin-bottom-nil__1EWXp {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.Banner_centered__2nvaV {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.Banner_main-body-header__NwtFn {
  background: #fff;
  padding: 16px 0; }

.Banner_ant-typography__22RJh .Banner_ant-typography-edit-content__2K75f {
  padding: 0; }

.Banner_clear__3atEy {
  clear: both; }

.Banner_tb_l_avatar_block_main__3_3-A {
  background: #a5a6f8;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #a5a6f8 0%, #6d6eed 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
  /* IE6-9 */
  overflow: hidden;
  padding: 4px;
  border-radius: 100%;
  overflow: hidden;
  width: 150px;
  height: 150px;
  margin: 0 auto 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

ul li {
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden; }

.Banner_tb_l_profile__name__2k4vv {
  font-weight: bold;
  text-align: center;
  margin-bottom: 4px; }
  .Banner_tb_l_profile__name__2k4vv .Banner_tb_l_profile__name_jtitle__1zNCr {
    color: #6d6d6d;
    display: block;
    font-weight: 300;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 24px;
    margin-bottom: 24px; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

.flex {
  display: flex; }
  .flex.flex-align-right > div:last-child {
    margin-left: auto; }

.row {
  width: 100%; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row {
    *zoom: 1; }

.wrapper:before, .wrapper:after {
  content: " ";
  display: table; }

.wrapper:after {
  clear: both; }

.wrapper {
  *zoom: 1; }

.wrapper:not(.tb-wrapper--fullwidth) {
  margin: 0 20px;
  max-width: 100%; }

.wrapper.tb-wrapper--fullwidth {
  max-width: 1322px;
  width: 90%;
  margin: 0 auto; }

.card {
  background: #fff;
  border-radius: 2px;
  border-radius: 2px;
  position: relative;
  margin: 0 0 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .card.tb-card--fullwidth {
    width: 100%; }
  .card.tb-card-border--light {
    border: 1px; }
  .card.tb-card-border--thick {
    border-width: 2px; }
  .card.tb-card--default {
    padding: 4px; }
  .card.tb-card--double-space {
    padding: 8px; }
  .card.tb-card--default--no-space {
    padding: 0; }
  .card.tb-card--default--no-margin {
    margin: 0; }
  .card.tb-card--default--bottom-spacer {
    margin-bottom: 24px; }

.tb_col_10 {
  width: 10%; }

.tb_col_20 {
  width: 20%; }

.tb_col_30 {
  width: 30%; }

.tb_col_50 {
  width: 50%; }

.tb_col_70 {
  width: 70%; }

.tb_col_40 {
  width: 40%; }

.tb_col_60 {
  width: 60%; }

.tb_col_80 {
  width: 80%; }

.tb_col_90 {
  width: 90%; }

.tb_col_default {
  width: 100%; }

.tb_col_quat {
  width: 33.33%; }

.tb_col_inline_block_space {
  float: none !important;
  display: inline-block !important;
  margin-right: 4px;
  margin-bottom: 8px !important; }
  .tb_col_inline_block_space button {
    background: transparent; }

.tb_l_spacer_small__left {
  margin-left: 8px; }

.tb_l_spacer_small__right {
  margin-right: 8px; }

.tb_l_spacer_larger__left {
  margin-left: 24px; }
  .tb_l_spacer_larger__left:before, .tb_l_spacer_larger__left:after {
    content: " ";
    display: table; }
  .tb_l_spacer_larger__left:after {
    clear: both; }
  .tb_l_spacer_larger__left {
    *zoom: 1; }

.tb_l_spacer_larger__right {
  margin-right: 24px; }
  .tb_l_spacer_larger__right:before, .tb_l_spacer_larger__right:after {
    content: " ";
    display: table; }
  .tb_l_spacer_larger__right:after {
    clear: both; }
  .tb_l_spacer_larger__right {
    *zoom: 1; }

.tb_l_spacer__large__right {
  margin-right: 20px; }
  .tb_l_spacer__large__right:before, .tb_l_spacer__large__right:after {
    content: " ";
    display: table; }
  .tb_l_spacer__large__right:after {
    clear: both; }
  .tb_l_spacer__large__right {
    *zoom: 1; }

.tb_l_spacer__large__left {
  margin-left: 20px; }
  .tb_l_spacer__large__left:before, .tb_l_spacer__large__left:after {
    content: " ";
    display: table; }
  .tb_l_spacer__large__left:after {
    clear: both; }
  .tb_l_spacer__large__left {
    *zoom: 1; }

.tb_l_spacer__large__breset {
  margin-bottom: 0; }
  .tb_l_spacer__large__breset:before, .tb_l_spacer__large__breset:after {
    content: " ";
    display: table; }
  .tb_l_spacer__large__breset:after {
    clear: both; }
  .tb_l_spacer__large__breset {
    *zoom: 1; }

.tb_l_spacer__large__lreset {
  margin-left: 0; }
  .tb_l_spacer__large__lreset:before, .tb_l_spacer__large__lreset:after {
    content: " ";
    display: table; }
  .tb_l_spacer__large__lreset:after {
    clear: both; }
  .tb_l_spacer__large__lreset {
    *zoom: 1; }

.tb_l_spacer__large__treset {
  margin-top: 0; }
  .tb_l_spacer__large__treset:before, .tb_l_spacer__large__treset:after {
    content: " ";
    display: table; }
  .tb_l_spacer__large__treset:after {
    clear: both; }
  .tb_l_spacer__large__treset {
    *zoom: 1; }

.tb_l_spacer__large__rreset {
  margin-right: 0; }
  .tb_l_spacer__large__rreset:before, .tb_l_spacer__large__rreset:after {
    content: " ";
    display: table; }
  .tb_l_spacer__large__rreset:after {
    clear: both; }
  .tb_l_spacer__large__rreset {
    *zoom: 1; }

.tb_l_list .tb_l_list__item {
  margin-bottom: 16px; }
  .tb_l_list .tb_l_list__item:last-child {
    margin-bottom: 0; }
  .tb_l_list .tb_l_list__item .tb_l_list__icon {
    display: inline-block;
    width: 12px;
    margin-right: 8px;
    position: relative;
    top: 2px; }

.tb_l_spacer__large {
  padding: 24px; }
  .tb_l_spacer__large:before, .tb_l_spacer__large:after {
    content: " ";
    display: table; }
  .tb_l_spacer__large:after {
    clear: both; }
  .tb_l_spacer__large {
    *zoom: 1; }

.tb_l_spacer__largest {
  padding: 32px; }
  .tb_l_spacer__largest:before, .tb_l_spacer__largest:after {
    content: " ";
    display: table; }
  .tb_l_spacer__largest:after {
    clear: both; }
  .tb_l_spacer__largest {
    *zoom: 1; }

.tb_l_spacer__medium {
  padding: 16px; }
  .tb_l_spacer__medium:before, .tb_l_spacer__medium:after {
    content: " ";
    display: table; }
  .tb_l_spacer__medium:after {
    clear: both; }
  .tb_l_spacer__medium {
    *zoom: 1; }

.tb_l_spacer_large__left {
  margin-left: 24px; }
  .tb_l_spacer_large__left:before, .tb_l_spacer_large__left:after {
    content: " ";
    display: table; }
  .tb_l_spacer_large__left:after {
    clear: both; }
  .tb_l_spacer_large__left {
    *zoom: 1; }

.tb_l_spacer_large__right {
  margin-right: 24px; }
  .tb_l_spacer_large__right:before, .tb_l_spacer_large__right:after {
    content: " ";
    display: table; }
  .tb_l_spacer_large__right:after {
    clear: both; }
  .tb_l_spacer_large__right {
    *zoom: 1; }

.tb_l_spacer_small__left {
  margin-left: 16px; }
  .tb_l_spacer_small__left:before, .tb_l_spacer_small__left:after {
    content: " ";
    display: table; }
  .tb_l_spacer_small__left:after {
    clear: both; }
  .tb_l_spacer_small__left {
    *zoom: 1; }

.tb_l_spacer_small__right {
  margin-right: 16px; }
  .tb_l_spacer_small__right:before, .tb_l_spacer_small__right:after {
    content: " ";
    display: table; }
  .tb_l_spacer_small__right:after {
    clear: both; }
  .tb_l_spacer_small__right {
    *zoom: 1; }

.tb_l_spacer {
  margin-bottom: 24px !important; }

.tb_l_spacer__smaller {
  margin-bottom: 2px !important; }

.tb_l_spacer__small {
  margin-bottom: 4px !important; }

.tb_l_spacer_b__medium {
  margin-bottom: 8px !important; }

.tb_l_spacer__large {
  margin-bottom: 16px !important; }
  .tb_l_spacer__large:last-child {
    margin-bottom: 0 !important; }

.tb_l_spacer__larger {
  margin-bottom: 24px !important; }

.tb_l_spacer__largest {
  margin-bottom: 32px !important; }
  .tb_l_spacer__largest:last-child {
    margin-bottom: 0 !important; }

p {
  margin-bottom: 8px; }
  p.tb_l_dates_ex {
    color: #6d6eed !important; }
  p.default {
    color: #000; }
  p.bold {
    font-weight: bold; }

[class*="col_"] {
  float: left; }

[class*="col_"].centered {
  float: none;
  margin-left: auto !important;
  margin-right: auto !important; }

.tb_l_button__link {
  background-color: transparent !important;
  border: none !important;
  padding: 4px !important; }
  .tb_l_button__link:hover {
    text-decoration: underline; }
  .tb_l_button__link.posi {
    color: #3bde86 !important; }
  .tb_l_button__link.neg {
    color: #e34f70 !important; }
  .tb_l_button__link.delete {
    color: #6d6d6d !important; }

.tb_l_button__primary {
  color: #fff !important;
  background-color: #6d6eed !important;
  border-color: #6d6eed !important; }

.tb_l_button__link {
  background-color: transparent;
  border: none;
  padding: 8px; }
  .tb_l_button__link.posi {
    color: #3bde86; }
  .tb_l_button__link.neg {
    color: #e34f70; }

.tb_l_button__interactive {
  background-color: transparent !important;
  border: 1px solid #d0d0d0 !important;
  color: #d0d0d0 !important;
  padding: 4px 8px !important; }
  .tb_l_button__interactive:hover {
    background-color: transparent !important;
    border: 1px solid #6d6eed !important;
    color: #6d6eed !important; }

.ghost_on {
  background-color: transparent !important;
  border: 1px solid #6d6eed !important;
  color: #6d6eed !important;
  padding: 4px 8px !important; }

.input_icons.cal {
  background: url(/static/media/calendar.0c07aeb2.svg) no-repeat 98% center; }

.input_icons.arrow {
  background: url(/static/media/arrow.d9e88261.svg) no-repeat 98% center; }

[type="date"] {
  background: #fff url(/static/media/calendar.0c07aeb2.svg) 97% 50% no-repeat; }

select {
  background: #fff url(/static/media/arrow.d9e88261.svg) 97% 50% no-repeat; }

[type="date"]::-webkit-inner-spin-button {
  display: none; }

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0; }

html,
body {
  min-height: 100%; }

.UpdatePictureButton_full-height__HprxC {
  min-height: 100%; }

.UpdatePictureButton_tb-margin-bottom-large__34p5X {
  margin-bottom: 24px;
  display: block; }

.UpdatePictureButton_tb-margin-bottom-larger__39VOw {
  margin-bottom: 40px;
  display: block; }

.UpdatePictureButton_tb-margin-bottom-medium__3i0L6 {
  margin-bottom: 16px;
  display: block; }

.UpdatePictureButton_tb-margin-bottom-small__3jgsC {
  margin-bottom: 8px !important;
  display: block; }

.UpdatePictureButton_tb-margin-bottom-nil__1nkWs {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.UpdatePictureButton_centered__1D54r {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.UpdatePictureButton_main-body-header__1CP15 {
  background: #fff;
  padding: 16px 0; }

.UpdatePictureButton_ant-typography__2wCPB .UpdatePictureButton_ant-typography-edit-content__37Kxl {
  padding: 0; }

.UpdatePictureButton_clear__1VaBV {
  clear: both; }

.UpdatePictureButton_file_container__3tyW0 {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin: 50px;
  background: #6d6eed;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff; }

.UpdatePictureButton_file_container__3tyW0 [type=file] {
  display: none; }

.Entry_item__2SdiH {
  margin-bottom: 16px; }

.Entry_item__2SdiH:last-child {
  margin-bottom: 0; }

.Entry_icon__1TtY6 {
  display: inline-block;
  width: 12px;
  margin-right: 8px;
  position: relative;
  top: 2px; }

.Entry_icon__1TtY6 .Entry_img__3Xhy5 {
  width: 100%;
  height: auto; }

.Entry_body__3cft- {
  display: inline-block; }

html,
body {
  min-height: 100%; }

.Card_full-height__3Tmzo {
  min-height: 100%; }

.Card_tb-margin-bottom-large__2eAoJ {
  margin-bottom: 24px;
  display: block; }

.Card_tb-margin-bottom-larger__3xvBp {
  margin-bottom: 40px;
  display: block; }

.Card_tb-margin-bottom-medium__1Cm9B {
  margin-bottom: 16px;
  display: block; }

.Card_tb-margin-bottom-small__1JdN_ {
  margin-bottom: 8px !important;
  display: block; }

.Card_tb-margin-bottom-nil__nmESP {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.Card_centered__3mQw2 {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.Card_main-body-header__2g5bd {
  background: #fff;
  padding: 16px 0; }

.Card_ant-typography__3f8_6 .Card_ant-typography-edit-content__3JR7Y {
  padding: 0; }

.Card_clear__2sVZu {
  clear: both; }

.Card_card__1Tu3l {
  background: #fff;
  border-radius: 2px;
  border-radius: 2px;
  position: relative;
  margin: 0 0 24px; }

.Wrapper_wrapper__tY4jn {
  max-width: 1322px;
  width: 90%;
  margin: 0 auto; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

.tb_l_avatar_block {
  float: right;
  position: relative;
  top: 4px;
  width: 230px; }
  .tb_l_avatar_block .tb_l_avatar {
    display: inline-block; }
  .tb_l_avatar_block .tb_l_avatar_block__text {
    display: inline-block;
    font-weight: bold;
    color: #0000009e;
    padding-left: 16px; }

html,
body {
  min-height: 100%; }

.Header_full-height__Yf5P0 {
  min-height: 100%; }

.Header_tb-margin-bottom-large__2wewO {
  margin-bottom: 24px;
  display: block; }

.Header_tb-margin-bottom-larger__2E30t {
  margin-bottom: 40px;
  display: block; }

.Header_tb-margin-bottom-medium__3Su2Q {
  margin-bottom: 16px;
  display: block; }

.Header_tb-margin-bottom-small__3cDWm {
  margin-bottom: 8px !important;
  display: block; }

.Header_tb-margin-bottom-nil__RJSRC {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.Header_centered__365Um {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.Header_main-body-header__1ToBg {
  background: #fff;
  padding: 16px 0; }

.Header_ant-typography__3swz- .Header_ant-typography-edit-content__yLn_S {
  padding: 0; }

.Header_clear__1Rbjr {
  clear: both; }

.Header_header__1cpuc {
  background: #fff;
  padding: 16px 0;
  border-bottom: 1px solid #e8e8e8; }
  .Header_header__1cpuc .Header_content__1XmJC {
    height: 32px;
    display: flex;
    align-items: center; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

html,
body {
  min-height: 100%; }

.full-height {
  min-height: 100%; }

.tb-margin-bottom-large {
  margin-bottom: 24px;
  display: block; }

.tb-margin-bottom-larger {
  margin-bottom: 40px;
  display: block; }

.tb-margin-bottom-medium {
  margin-bottom: 16px;
  display: block; }

.tb-margin-bottom-small {
  margin-bottom: 8px !important;
  display: block; }

.tb-margin-bottom-nil {
  margin-bottom: 0 !important;
  display: block; }

body {
  background-color: #f7f8fc; }

.centered {
  max-width: 1366px;
  margin: 0 auto;
  width: 92%; }

.main-body-header {
  background: #fff;
  padding: 16px 0; }

.ant-typography .ant-typography-edit-content {
  padding: 0; }

.clear {
  clear: both; }

.logo {
  padding: 0;
  top: 8px;
  display: inline-block;
  position: relative; }

.logo img {
  display: inline-block;
  width: 70px;
  height: auto; }

.logo span {
  display: inline-block;
  margin-left: 16px;
  border-left: 1px solid #d0d0d0;
  padding-top: 4px;
  padding-left: 16px;
  padding-bottom: 4px;
  position: relative;
  color: #6d6eed; }

