@import "~@bit/tibu.web.styles.base";

.header {
  background: #fff;
  padding: 16px 0;
  border-bottom: 1px solid #e8e8e8;

  .content {
    height: 32px;
    display: flex;
    align-items: center;
  }
}