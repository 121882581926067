@import "~@bit/tibu.web.styles.base";

.tb_l_avatar_block_main {
  background: #a5a6f8;
  /* Old browsers */
  background: -moz-linear-gradient(top, #a5a6f8 0%, #6d6eed 100%);
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #a5a6f8),
    color-stop(100%, #6d6eed)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #a5a6f8 0%, #6d6eed 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #a5a6f8 0%, #6d6eed 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #a5a6f8 0%, #6d6eed 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #a5a6f8 0%, #6d6eed 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
  /* IE6-9 */
  overflow: hidden;
  padding: $tb-l-units-smallest;
  border-radius: 100%;
  overflow: hidden;
  width: 150px;
  height: 150px;
  margin: 0 auto $tb-l-units-larger;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
}
ul {
  li {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }
}
.tb_l_profile__name {
  font-weight: bold;
  text-align: center;
  margin-bottom: $tb-l-units-smallest;
  .tb_l_profile__name_jtitle {
    color: $tb-text-compliment;
    display: block;
    font-weight: 300;
    border-bottom: $tb-l-lines;
    padding-bottom: $tb-l-units-larger;
    margin-bottom: $tb-l-units-larger;
  }
}
