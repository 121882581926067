@import "~@bit/tibu.web.styles.base";
$base-padding: $tb-l-units-micro * 2;

.flex {
  display: flex;
  &.flex-align-right > div:last-child {
    margin-left: auto;
  }
}
.row {
  width: 100%;
  @include clearfix;
}
.wrapper {
  @include clearfix;
  &:not(.tb-wrapper--fullwidth) {
    margin: 0 $tb-l-units-large;
    max-width: 100%;
  }
  &.tb-wrapper--fullwidth {
    max-width: 1322px;
    width: 90%;
    margin: 0 auto;
  }
}

.card {
  background: $tb-white;
  border-radius: $tb-l-units-micro;
  border-radius: 2px;
  position: relative;
  margin: 0 0 $tb-l-units-larger;
  box-shadow: $tb-l-shadow;
  &.tb-card--fullwidth {
    width: 100%;
  }

  &.tb-card-border--light {
    border: 1px;
  }
  &.tb-card-border--thick {
    border-width: $tb-l-units-micro;
  }

  &.tb-card--default {
    padding: $base-padding;
  }

  &.tb-card--double-space {
    padding: $base-padding * 2;
  }

  &.tb-card--default--no-space {
    padding: 0;
  }
  &.tb-card--default--no-margin {
    margin: 0;
  }
  &.tb-card--default--bottom-spacer {
    margin-bottom: $tb-l-units-larger;
  }
}
.tb_col_10 {
  width: 10%;
}
.tb_col_20 {
  width: 20%;
}
.tb_col_30 {
  width: 30%;
}
.tb_col_50 {
  width: 50%;
}
.tb_col_70 {
  width: 70%;
}
.tb_col_40 {
  width: 40%;
}
.tb_col_60 {
  width: 60%;
}
.tb_col_80 {
  width: 80%;
}
.tb_col_90 {
  width: 90%;
}
.tb_col_default {
  width: 100%;
}
.tb_col_quat {
  width: 33.33%;
}
.tb_col_inline_block_space {
  float: none !important;
  display: inline-block !important;
  margin-right: $tb-l-units-smallest;
  margin-bottom: $tb-l-units-smaller !important;
  button {
    background: transparent;
  }
}
.tb_l_spacer_small__left {
  margin-left: $tb-l-units-smaller;
}
.tb_l_spacer_small__right {
  margin-right: $tb-l-units-smaller;
}
.tb_l_spacer_larger__left {
  @include clearfix;
  margin-left: $tb-l-units-larger;
}
.tb_l_spacer_larger__right {
  @include clearfix;
  margin-right: $tb-l-units-larger;
}
.tb_l_spacer__large__right {
  @include clearfix;
  margin-right: $tb-l-units-large;
}
.tb_l_spacer__large__left {
  @include clearfix;
  margin-left: $tb-l-units-large;
}
.tb_l_spacer__large__breset {
  @include clearfix;
  margin-bottom: 0;
}
.tb_l_spacer__large__lreset {
  @include clearfix;
  margin-left: 0;
}
.tb_l_spacer__large__treset {
  @include clearfix;
  margin-top: 0;
}
.tb_l_spacer__large__rreset {
  @include clearfix;
  margin-right: 0;
}
.tb_l_list {
  .tb_l_list__item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .tb_l_list__icon {
      display: inline-block;
      width: 12px;
      margin-right: 8px;
      position: relative;
      top: 2px;
    }
  }
}
.tb_l_spacer__large {
  @include clearfix;
  padding: $tb-l-units-larger;
}
.tb_l_spacer__largest {
  @include clearfix;
  padding: $tb-l-units-largest;
}
.tb_l_spacer__medium {
  @include clearfix;
  padding: $tb-l-units-medium;
}
.tb_l_spacer_large__left {
  @include clearfix;
  margin-left: $tb-l-units-larger;
}
.tb_l_spacer_large__right {
  @include clearfix;
  margin-right: $tb-l-units-larger;
}
.tb_l_spacer_small__left {
  @include clearfix;
  margin-left: $tb-l-units-medium;
}
.tb_l_spacer_small__right {
  @include clearfix;
  margin-right: $tb-l-units-medium;
}
.tb_l_spacer {
  margin-bottom: 24px !important;
}

.tb_l_spacer__smaller {
  margin-bottom: 2px !important;
}

.tb_l_spacer__small {
  margin-bottom: 4px !important;
}

.tb_l_spacer_b__medium {
  margin-bottom: 8px !important;
}

.tb_l_spacer__large {
  &:last-child {
    margin-bottom: 0 !important;
  }
  margin-bottom: 16px !important;
}
.tb_l_spacer__larger {
  margin-bottom: 24px !important;
}
.tb_l_spacer__largest {
  margin-bottom: 32px !important;
  &:last-child {
    margin-bottom: 0 !important;
  }
}
p {
  margin-bottom: 8px;
  &.tb_l_dates_ex {
    color: $tb-brand !important;
  }
  &.default {
    color: $tb-black;
  }
  &.bold {
    font-weight: bold;
  }
}
[class*="col_"] {
  float: left;
}
[class*="col_"] {
  &.centered {
    float: none;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.tb_l_button__link {
  background-color: transparent !important;
  border: none !important;
  padding: $tb-l-units-smallest !important;
  &:hover {
    text-decoration: underline;
  }
  &.posi {
    color: $tb-constructive !important;
  }
  &.neg {
    color: $tb-destructive !important;
  }
  &.delete {
    color: #6d6d6d !important;
  }
}
.tb_l_button__primary {
  color: $tb-white !important;
  background-color: $tb-brand !important;
  border-color: $tb-brand !important;
}
.tb_l_button__link {
  background-color: transparent;
  border: none;
  padding: $tb-l-units-smaller;
  &.posi {
    color: $tb-constructive;
  }
  &.neg {
    color: $tb-destructive;
  }
}
.tb_l_button__interactive {
  background-color: transparent !important;
  border: 1px solid #d0d0d0 !important;
  color: $tb-lines !important;
  padding: $tb-l-units-smallest $tb-l-units-smaller !important;
  &:hover {
    background-color: transparent !important;
    border: 1px solid $tb-brand !important;
    color: $tb-brand !important;
  }
}
.ghost_on {
  background-color: transparent !important;
  border: 1px solid $tb-brand !important;
  color: $tb-brand !important;
  padding: $tb-l-units-smallest $tb-l-units-smaller !important;
}
.input_icons {
  &.cal {
    background: url(./calendar.svg) no-repeat 98% center;
  }
  &.arrow {
    background: url(./arrow.svg) no-repeat 98% center;
  }
}
[type="date"] {
  background: #fff url(./calendar.svg) 97% 50% no-repeat;
}
select {
  background: #fff url(./arrow.svg) 97% 50% no-repeat;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
