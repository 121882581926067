@import "~@bit/tibu.web.styles.base";
$base-font: $tb-l-units-smallest * 4;

.tb-l-text {
  line-height: $tb-l-lineheight;

  &.tb-l-text-size--default {
    font-size: $base-font;
  }
  &.tb-l-text-size--sm {
    font-size: $base-font - $tb-l-units-smallest;
  }
  &.tb-l-text-size--lg {
    font-size: $base-font + $tb-l-units-smallest;
  }
  &strong,
  &.tb-l-text--semibold {
    font-weight: 600;
  }
  &.tb-l-text--bold {
    font-weight: 700;
  }

  &.tb-l-text-align--right {
    text-align: right;
  }
}
