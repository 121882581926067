@import "~@bit/tibu.web.styles.base";

.tb_l_avatar_block {
  float: right;
  position: relative;
  top: $base-padding / 2;
  width: 230px;
  .tb_l_avatar {
    display: inline-block;
  }
  .tb_l_avatar_block__text {
    display: inline-block;
    font-weight: bold;
    color: #0000009e;
    padding-left: $base-padding * 2;
  }
}
