@import "~@bit/tibu.web.styles.base";



.file_container {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin: 50px;
    background: $tb-brand;
    text-decoration-color: #fff;
    
}

.file_container [type=file] {
    display: none;
}
